import axios from "axios";
import { API_URL } from "@/const";

export default {
  data() {
    return {
      generatorCollectList: [],
      selectGeneratorCollectList: [], //入力テキストで絞る排出事業者選択肢
      selectGeneratorDisposalList: [], //入力テキストで絞る排出事業者選択肢
      selectGenerateStoreList: [], //入力テキストで絞る排出事業場選択肢
      generatorDisposalList: [],
      selectDisposalSiteList: [], //入力テキストで絞る処分場選択肢
      disposalSiteList: [],
      disposalSiteOptions: [], // 処分事業者IDで絞り込まずに、処分場選択肢
      // API返り値（選択肢などに仕様）
      generateStoreOpt: [],
      memberOpt: [],
      memberDisOpt: [],
      jwnetItemsOpt: [],
      recurryingTypesOpt: [],
      routesInfo: [],
      routeDetailInfo: {},
      wasteUnitOpt: [],
      confirmMemberOpt: [],
      shapeOpt: [],
      routeLabels: [],
      disposalWayTypesOpt: [],
      wasteNameOpt: [],
      harmfulTypes: [],
      prefectures: [],
      // ルート協議書アラート情報取得からの警告メッセージ
      routePriorConsultationAlertMsgs: "",
      routePriorConsultationIsAlert: false, // 事前協議書の警告基準値に到達したかどうか
      routePriorConsultationIsLimit: false, // 事前協議書の上限値を超過したかどうか
      // 取得済み番号利用するか
      isUseAcquiredManifestNo: false,
      generateMemberMetaInfo: {}, //設定した引き渡し担当者情報
    };
  },
  methods: {
    //事業場選択肢取得API    API_URL.SCHEDULE.GENERATE_STORE
    getGenerateStoreOptApi() {
      return axios
        .get(API_URL.SCHEDULE.GENERATE_STORE)
        .then((res) => {
          res.data.generateCompanyList.forEach((company) => {
            company.generateStoreList.forEach((store) => {
              this.generateStoreOpt.push({
                id: store.id,
                name: `${company.generateCompanyName} - ${store.name}`
              });
              this.selectGenerateStoreList.push({
                id: store.id,
                name: `${company.generateCompanyName} - ${store.name}`
              });
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 担当者一覧API    API_URL.MANIFEST.MEMBER_OPT
    // 担当者一覧API（排出）    API_URL.MANIFEST.MEMBER_OPT
    getMemberOptApi(generateStoreId) {
      return axios
        .get(API_URL.MANIFEST.MEMBER_OPT + generateStoreId)
        .then((res) => {
          this.memberOpt = res.data.MemberOptions;
          this.memberDisOpt = res.data.MemberOptions;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 委託契約に紐づく廃棄物種類選択肢取得API    API_URL.ENTRUST.JWNET_ITEM_TYPE
    getEntrustJwnetItemsApi(generateStoreId) {
      axios
        .get(API_URL.ENTRUST.JWNET_ITEM_TYPE + `?generateStoreId=${generateStoreId}`)
        .then((res) => {
          this.jwnetItemsOpt = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 繰り返し情報選択肢取得API     API_URL.DOMAIN.RECURRING
    getRecurryingApi() {
      axios
        .get(API_URL.DOMAIN.RECURRING)
        .then((res) => {
          this.recurryingTypesOpt = res.data.recurringTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート選択肢検索Urlの作成
    getRouteListSearchApiUrl() {

      const params = new URLSearchParams();
      if (this.routeModalSearchParam.jwnetItemTypeId) params.append("jwnetItemTypeId", this.routeModalSearchParam.jwnetItemTypeId);
      if (this.routeModalSearchParam.routeName) params.append("routeName", this.routeModalSearchParam.routeName);
      if (this.routeModalSearchParam.wasteName) params.append("wasteName", this.routeModalSearchParam.wasteName);
      if (this.routeModalSearchParam.collectCompanyId) params.append("collectCompanyId", this.routeModalSearchParam.collectCompanyId);
      if (this.routeModalSearchParam.disposalCompanyId) params.append("disposalCompanyId", this.routeModalSearchParam.disposalCompanyId);
      if (this.routeModalSearchParam.disposalSiteId) params.append("disposalSiteId", this.routeModalSearchParam.disposalSiteId);

      if (this.routeModalSearchParam.wasteTypeIdList) params.append("wasteTypeIdList", this.routeModalSearchParam.wasteTypeIdList);
      if (this.routeModalSearchParam.industrialWasteJwnetTypeIdList) params.append("industrialWasteJwnetTypeIdList", this.routeModalSearchParam.industrialWasteJwnetTypeIdList);
      if (this.routeModalSearchParam.manifestTypeIdList) params.append("manifestTypeIdList", this.routeModalSearchParam.manifestTypeIdList);
      if (this.routeModalSearchParam.isFavorite) params.append("isFavorite", this.routeModalSearchParam.isFavorite);

      return API_URL.ROUTE.LIST+ this.formValue.generateStoreId + '?' + params.toString();
    },

    // ルート選択肢取API      API_URL.ROUTE.LIST
    getRouteListSearchApi() {
      axios
        .get(this.getRouteListSearchApiUrl())
        .then((res) => {
          this.routesInfo = res.data.routeList;
          this.narrowedRoutesList = [...this.routesInfo];
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート情報API API_URL.ROUTE.DETAIL/{routeId}
    getRouteInfoApi(routeId) {
      return axios
        .get(API_URL.ROUTE.DETAIL + routeId)
        .then((res) => {
          this.routeDetailInfo = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物数量単位選択肢取得API    API_URL.DOMAIN.WASTE_UNIT
    getWasteUnitApi() {
      axios
        .get(API_URL.DOMAIN.WASTE_UNIT)
        .then((res) => {
          this.wasteUnitOpt = res.data.wasteUnitTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 数量確定者選択肢取得API    API_URL.MANIFEST.CONFIRM_MEMBER
    getConfirmMemberApi(routeId) {
      axios
        .get(`${API_URL.MANIFEST.CONFIRM_MEMBER}${routeId}`)
        .then((res) => {
          this.confirmMemberOpt = res.data.quantityConfirmMembers;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート荷姿選択肢取得API    API_URL.ROUTE.ROUTE_SHAPE_TYPE
    getShapeApi(routeId) {
      axios
        .get(API_URL.ROUTE.ROUTE_SHAPE_TYPE + routeId)
        .then((res) => {
          this.shapeOpt = res.data.shapeTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート処分方法選択肢取得API    API_URL.ROUTE.ROUTE_DISPOSAL_WAY_TYPE
    getDisposalWayTypesApi(routeId) {
      axios
        .get(API_URL.ROUTE.ROUTE_DISPOSAL_WAY_TYPE + routeId)
        .then((res) => {
          this.disposalWayTypesOpt = res.data.disposalWayTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // CBA品目別廃棄物名称選択肢取得API    API_URL.DOMAIN.WASTE_NAME_CBA_ITEM
    getWasteNameCBAItemApi(cbaItemId) {
      axios
        .get(API_URL.DOMAIN.WASTE_NAME_CBA_ITEM(cbaItemId))
        .then((res) => {
          this.wasteNameOpt = res.data.wasteNameList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 有害物質名選択肢取得API    API_URL.DOMAIN.HARMFUL_TYPE
    getharmfulApi() {
      axios
        .get(API_URL.DOMAIN.HARMFUL_TYPE)
        .then((res) => {
          this.harmfulTypes = res.data.harmfulTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 都道府県選択肢取得API   DOMAIN.PREFECTURES
    getPrefecturesApi() {
      return axios
        .get(API_URL.DOMAIN.PREFECTURES)
        .then((res) => {
          this.prefectures = res.data.prefectures;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルートに紐づく事前協議書のアラート情報取得API
    getRoutePriorconsultationAlert() {
      this.routePriorConsultationAlertMsgs = "";
      this.routePriorConsultationIsLimit = false;
      this.routePriorConsultationIsAlert = false;
      const params = new URLSearchParams();
      if (this.formValue.manifestInfo.routeId) { params.append('routeId', this.formValue.manifestInfo.routeId) } else return
      if (this.formValue.scheduleSettingInfo.firstCollectionDate) params.append('collectionDate', this.formValue.scheduleSettingInfo.firstCollectionDate);
      return axios
        .get(`${API_URL.ROUTE.ROUTE_PRIORCONSULTATION_ALERT}` + '?' + params.toString())
        .then((res) => {
          if (res.data.isLimit) {
            this.routePriorConsultationIsLimit = true;
            this.routePriorConsultationAlertMsgs = '事前協議書の上限値を超過しています。'
          } else if (res.data.isAlert) {
            this.routePriorConsultationIsAlert = true;
            this.routePriorConsultationAlertMsgs = '事前協議書の警告基準値に到達しました。'
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 運搬事業者選択肢取得API
    getGeneratorCollectApi() {
      this.getGeneratorCollectSelection(API_URL.MANIFEST.GENERATOR_COLLECT_LIST).then((data) => {
        this.generatorCollectList = data;
        this.selectGeneratorCollectList = data;
      }).catch(() => {})
    },

    // 処分事業者選択肢取得API
    getGeneratorDisposalApi() {
      this.getGeneratorDisposalSelection().then((data) => {
        this.generatorDisposalList = data;
        this.selectGeneratorDisposalList = data;
      }).catch(() => {})
    },

    // 処分事業場取得API
    getDisposalSiteApi() {
      this.getDisposalSiteSelection().then((data) => {
        this.disposalSiteList = data
        this.selectDisposalSiteList = data;
        this.disposalSiteOptions = data;
      }).catch(() => {})
    },

    // 番号取得方法取得API
    getManifestNoGetWayApi() {
      return axios
        .get(API_URL.MANIFEST.MANIFEST_NO_GET_WAY)
        .then((res) => {
          this.isUseAcquiredManifestNo = res.data.isUseAcquiredManifestNo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // マニフェスト引き渡し担当者メンバーの拡張情報取得API
    getGenerateMemberGetWayApi(generateStoreId) {
      return axios
        .get(API_URL.MANIFEST.GENERATE_MEMBER_GET_WAY + generateStoreId)
        .then((res) => {
          this.generateMemberMetaInfo = res.data.generateMemberMetaInfo;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
